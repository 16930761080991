import dayjs from 'dayjs';
import {
  UserCircleIcon,
  BuildingStorefrontIcon,
  MapPinIcon,
} from '../../../../../../common/components/icons/Icons';
import { BodyBolderText } from '../../../../../../common/components/texts';
import { formatDateToWrittenDateTime } from '../../../../../../common/utils/date.utils';
import styles from './SummarySectionNew.module.css';
import IconRow from './IconRow';

type DateTimeProps = {
  time: string;
  totalDuration: number;
  className?: string;
};
const DateTime = ({ time, totalDuration, className }: DateTimeProps) => {
  const endTime = dayjs(time).add(totalDuration, 'm').format('HH:mm');
  const text = time
    ? `${formatDateToWrittenDateTime(time)}-${endTime}`
    : totalDuration !== 0
    ? `${totalDuration} min`
    : null;

  return (
    <div className={className}>
      <BodyBolderText>{text}</BodyBolderText>
    </div>
  );
};

type BookingDetailsProps = {
  time: string;
  totalDuration: number;
  clinicName: string;
  clinicAddress: string;
  resourceName: string;
};
const BookingDetails = ({
  clinicName,
  clinicAddress,
  resourceName,
  time,
  totalDuration,
}: BookingDetailsProps) => {
  if (!clinicName && !clinicAddress && !resourceName) {
    return <DateTime time={time} totalDuration={totalDuration} />;
  }

  return (
    <div className={styles.bookingDetails}>
      <DateTime time={time} totalDuration={totalDuration} className={styles.dateTimeContainer} />
      {resourceName && <IconRow icon={<UserCircleIcon />}>{resourceName}</IconRow>}
      {clinicName && <IconRow icon={<BuildingStorefrontIcon />}>{clinicName}</IconRow>}
      {clinicAddress && <IconRow icon={<MapPinIcon />}>{clinicAddress}</IconRow>}
    </div>
  );
};

export default BookingDetails;
